.nav-menu {
  text-align: center;
  li {
    display: inline-block;
    padding: 0 2em;
    &:nth-child(1) {
      border: none;
    }
    a {
      color: $light;
      text-transform: uppercase;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.47843137254901963);
      &:hover {
        color: $nav-link-color-hover;
      }
    }
  }
}

@media screen and (min-width: $medium) {
  .nav-menu {
    li {
      border-left: 1.5px solid;
    }
  }
}

// Fun Animation on Hover
.nav-menu li a:after {
  content: " ";
  background: white;
  display: block;
  height: 1px;
  width: 0;
  transition: 400ms all ease;
}

.nav-menu li a:hover:after {
  width: 100%;
}

#site-navigation li {
  display: block;
}

/* Accessibility Friendly Navigation */
nav.main-navigation {
  text-align: left;
  z-index: $header-nav-zindex;
  .menu-item a {
    line-height: $fields-height;
    display: block;
    position: relative;
    text-decoration: none;
  }
  /* General */
  li {
    position: relative;
    //padding: 0 20px;

  }
  ul {
    display: none;
    list-style: none;
    margin: 0;
    &.toggled-on {
      display: block;
      > ul {
        display: block;
      }
    }
  }
  ul ul {
    display: none;
    // padding: .8em;
  }
  .current-menu-item > a,
  .current-menu-ancestor > a {
    font-weight: 700;
  }
  &.toggled .nav-menu {
    display: block;
  }
  .nav-menu {
    padding: 1em;
    background: $mobile-nav-popup-background;
    border: $mobile-nav-popup-border;
  }
  .nav-menu > ul > li:first-child,
  .nav-menu > li:first-child {

  }
  .menu-item-has-children > a {
    padding-right: 30px;
  }
  .menu-item-description {
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 0.5em;
  }
  .dropdown-toggle {
    background-color: transparent;
    border: 0;
    box-sizing: content-box;
    content: "";
    height: 27px;
    width: 27px;
    top: 7px;
    right: 0px;
    padding: 0;
    position: absolute;
    text-transform: lowercase; /* Stop screen readers to read the text as capital letters */
    &:after {
      content: "\f107";
      font-family: 'FontAwesome';
      font-size: 1em;
      line-height: 27px;
      position: relative;
      top: 2px;
      width: 42px;
    }
    &:hover,
    &:focus {
    }
    &:focus {
      outline: 1px solid #c1c1c1;
      outline: 1px solid rgba(51, 51, 51, 0.3);
    }
    &.toggle-on:after {
      content: "\f106";
    }
  }
  &.toggled {
    position: fixed;
    bottom: 80px;
    right: 1em;
    left: 1em;
    z-index: $header-nav-zindex;
    a {
    }
  }
  &.toggled ul.nav-menu {
    max-height: calc(100vh - 8em);
    overflow-y: auto;
  }
  /* Mobile Menu & Button Colors */
  a {
    font-family: $font-secondary;
  }
  a, i:before, button:after {
    color: $mobile-nav-link-color;
  }
  button.menu-toggle {
    //position: fixed;
    //top: 10px;
    //right: 1em;
    padding: 10px 20px;
    z-index: $header-nav-zindex;
    transition: 400ms bottom ease;
    position: fixed;
    bottom: 20px;
    color: $mobile-nav-button-text;
    border: 1px solid $mobile-nav-border-color;
    //background: $mobile-nav-button-background;
    background: rgba(0, 0, 0, 0.47843137254901963);
    &.nav-hide {
      bottom: -50px;
    }
  }

}

.toggled .nav-hide {
  bottom: 20px !important;
}

.no-js .main-navigation ul ul {
  display: block;
}

@media screen and (min-width: $medium) {

  nav.main-navigation {
    button.menu-toggle {
      position: initial;
      background: transparent;
      border: transparent;
      &.nav-hide {
        bottom: initial;
      }
    }
    &.toggled {
      bottom: initial;
      right: initial;
      left: initial;
    }
  }

}

@media screen and (min-width: $medium) {
  //button.menu-toggle {
  //  position: initial;
  //  display: none;
  //  bottom: initial;
  //}
  //#headerMenu {
  //  a, i:before, button:after {
  //    color: $nav-link-color;
  //  }
  //  a:hover,
  //  a:focus,
  //  i:hover:before,
  //  i:focus:before,
  //  button:focus:after,
  //  button:hover:after {
  //    color: $nav-link-color-hover;
  //  }
  //}
  //nav.main-navigation {
  //  color: $nav-text-color;
  //  // Link Color
  //  li {
  //    display: inline-block;
  //  }
  //  .nav-menu {
  //    padding: 0;
  //    display: block;
  //    background: transparent;
  //    margin: 0;
  //    border: none;
  //  }
  //  .sub-menu {
  //    background: $nav-dropdown-background;
  //    z-index: $header-nav-zindex;
  //    padding: 0 1em;
  //    text-align: left;
  //    li {
  //      padding: 0;
  //    }
  //    a, i:before, button:after {
  //      color: $light !important;
  //    }
  //    &.toggled-on {
  //      position: absolute;
  //      right: 0;
  //    }
  //    .sub-menu {
  //      position: initial !important;
  //      padding-left: 1em;
  //    }
  //  }
  //}
}