header.banner {
  text-align: center;
  z-index: $header-nav-zindex;
  background: rgba(0, 0, 0, 1);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.51) 49%, rgba(255, 255, 255, 0) 100%);
}

header.banner .container {
  height: inherit;
  .navbar-brand {
    float: none;
    width: 100%;
    padding: 0;
    height: inherit;
    h1#logo {
      margin: 0;
      height: inherit;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      //width: 59.6%;
      margin: 0 auto;
    }
  }
  a.phoneNumber {
    line-height: 1.2em;
    font-size: 1.4em;
    color: $light;
  }
  .social-icons ul {
    margin: 0;
    li {
      display: inline-block;
      line-height: 1em;
      padding: 0;
    }
    a {
      padding-right: 6px;
      line-height: $fields-height;
      &:hover i:before {
        color: w
      }
    }
  }

}

@media screen and (min-width: $small) {

}

@media screen and (min-width: $medium) {
  header.banner {
    float: left;
    .container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > * {
        flex: 1;
      }
    }
    .navbar-brand {
      float: left;
      h1#logo {

      }
      img {
        float: left;
        //height: 100%;
      }
    }
    #headerMenu {
      float: right;
    }
    .phoneNumber {
      float: right;
    }
    .social-icons {
      clear: both;
      text-align: right;
      ul {
        padding: 0;
      }
      li {
      }
    }
    nav {
      clear: both;
      float: right;
    }
  }
  #secondary-navigation {
    width: 100%;
    margin-bottom: 2em;
    padding-top: 180px;
    font-size: 1.2em;
  }
  // Fixed Header Settings
  header.banner {
    position: fixed;
    width: 100%;
    transition: 400ms all ease;
    #logo {
      margin: 0;
      img {
        margin: 1em;
        transition: .75s all ease;
        height: 130px;
        z-index: 999;
      }
    }
  }
  nav#site-navigation {
    position: relative;
  }

  ul#menu-primary {
    position: absolute;
    right: 0;
    z-index: 999;
    min-width: 370px;
    li {
      border: none;
    }
  }

  .fixedHeader {
    //background: rgba(0, 0, 0, 0.2784313725490196) !important;
    .container {

      z-index: 99;
    }
    #logo {

      img {
        height: 50px !important;
      }
    }
  }
}

//#site-navigation.toggled .fa-navicon:before {
//  content: "\f00d" !important;
//}
#site-navigation.toggled {
  .bt-bars:before {
    content: "\F00C" !important;
  }
}

#site-navigation ul#menu-primary {
  text-align: left;
}

button.menu-toggle i {
  font-size: 1.5em;
}



