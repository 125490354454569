.blurb {
    margin: 3em 0;
  text-align: center;
  h1,h2,h3,h4,h5 {
    color: $blurb-heading-color;
  }
  p {
    color: $blurb-text-color;
  }
  &:hover i {
    color: $blurb-icon-hover;
    // transform: rotate(15deg);
    animation: 1s pulse;
  }
  &:hover i:after {
    background: $blurb-icon-background-hover;
    border-color: $blurb-icon-border-hover;
  }
  i {
    //transition: 400ms all ease;
    font-size: 5em;
    color: $blurb-icon;
    position: relative;
    width: 100%;
    height: 1em;
    &:before {
      border-radius: 90%;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      // Random Centering Fix - This seems ridiculous
      padding-left: 7px;
    }
    &:after {
      content: " ";
      background: $blurb-icon-background;
      border: .5px solid $blurb-icon-border;
      border-radius: 90%;
      width: 150px;
      height: 150px;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
    }

  }
}