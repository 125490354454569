// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}



// Input Number Spinners - Web Supports Sucks for this
input[type="number"] {
  position: relative;
  margin: 0 0 1rem;
}

/* Spin Buttons modified */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  background: #FFF url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAABECAYAAABqMK75AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6REYzNzE1ODMxQTZGMTFFNkJDOUJBMTM5NTlERjJDREUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6REYzNzE1ODQxQTZGMTFFNkJDOUJBMTM5NTlERjJDREUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpERjM3MTU4MTFBNkYxMUU2QkM5QkExMzk1OURGMkNERSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpERjM3MTU4MjFBNkYxMUU2QkM5QkExMzk1OURGMkNERSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmT8A+wAAACESURBVHja7NfNDYAgDIBRME6iu+kE4ArKBrqbrlLr3R+MEDl8TQgHmhfSkgBWREyuqEzGAAcHB/8d996POXc+UPPPYa+uOW1eo9MaYbQhhO105cBjh3NO3uTT0CT4lOS0UHNwcHBwcHDwwvD64ZvS6TTfpPT6wl14FIGDg4ODl4nvAgwAZrRdfy14Y9MAAAAASUVORK5CYII=) no-repeat center center;
  background-size: cover;
  width: 1em;
  border-left: 1px solid #BBB;
  opacity: .9; /* shows Spin Buttons per default (Chrome >= 39) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
input[type="number"]::-webkit-inner-spin-button:hover,
input[type="number"]::-webkit-inner-spin-button:active{
  box-shadow: 0 0 2px #0CF;
  opacity: .8;
}