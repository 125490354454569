.archive, .single {
  .content {
    margin-top: 3em;
  }
}

.page-header {
  border-bottom: 1px solid $light;
}

// FAQ
#accordion {
  overflow: hidden;
  li {
    width: 100%;
  }
  header {
    @include flexbox(center);
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1em;
    overflow: hidden;
  }
  i {
    width: 10%;
  }
  h3 {
    margin: 0;
    width: 90%;
  }

  article {
    padding-left: 10%;
    max-height: 0;
    overflow: hidden;
    max-width: 600px;
    transition: 500ms max-height ease;

    &.active {
      max-height: 1000px;
    }
  }
  .toggle-active {
    i:before {
      content: "\f021";
    }
  }
}