aside.sidebar {
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  section {
    flex: 1 auto;
    float: left;
    border: 1px solid $light;
    padding: 1em 2em;
    margin: 0 1em 2em;
  }
  //section:nth-child(1) {
  //  margin-left: 0;
  //}
  //section:nth-child(4) {
  //  margin-right: 0;
  //}
  h1, h2, h3, h4, h5 {
    margin-top: 0;
  }
  .widget.amenities {
    li {
      list-style-type: disc;
      padding: 0;
    }
  }
}
