$homeBorder: 15px solid;

.home {
  main.main {
    padding: 0;
    max-width: 100%;
    margin: 0 auto;
    float: initial;
  }
}

.home {
  .homeFeaturedArea {
    padding: 0;
    .container {
      display: flex;
      flex-flow: row wrap;
    }
  }
  .carousel, .topRight, .topLeft {
    border: $homeBorder;
  }
  @media screen and (min-width: $medium) {
    .carousel {
      margin-right: 1em;
    }
  }
  .homeFeaturedRight {
    margin: 1em 0 0;
    @include flexbox(center);
    align-content: space-between;
    flex: 1 100%;
    @media screen and (min-width: $medium) {
      margin: 0;
      flex: 1;
    }
  }
  .topRight, .topLeft {
    width: 100%;
    height: 49%;
    @include flexbox(center);
    > a {
      min-height: 300px;
    }
    a, .innerBox, h3 {
      width: 100%;
      height: 100%;
    }
    .innerBox {

      h3 {
        background: rgba(1, 1, 1, .2);
        @include flexbox(center);
        text-align: center;
        margin: 0;
        color: $light;
      }
    }
  }
  .homeFeaturedLeft {
    flex: 1 100%;
    @media screen and (min-width: $medium) {
      flex: 3;
    }
    display: flex;
    > * {
      flex: 1;
    }
    #myCarousel {
      flex: 3;
    }
  }
}


// About
.home {
  .about {
    text-align: center;
    padding: 4em 0;
  }
}


/*
 *
 Homepage
 */
//.homeFeaturedArea.row {
//  h1, h2, h3, h4, h5, p {
//    font-family: 'harabarahanditalic';
//  }
//  padding-top: 0;
//}
//
//
//
//.homeFeaturedRight > * {
//  height: 50%;
//}
//
//.innerBox {
//  height: 100%;
//}
//
//$otherOverlay: rgba(0, 0, 0, 0.10196078431372549);
//
//.innerBox h3 {
//  margin: 0;
//  width: 100%;
//  background: $otherOverlay;
//  height: 100%;
//  text-align: center;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//}
//
//.overlay {
//  background: $otherOverlay;
//  z-index: 1;
//  width: 100%;
//  height: 100%;
//  position: absolute;
//}
//
//.homeFeaturedLeft, .homeFeaturedRight .innerBox {
//  border: $homeBorder;
//  box-shadow: 1px 5px 20px black;
//  color: $light;
//}
//
//.homeFeaturedRight {
//  > * {
//    height: 300px;
//    margin-top: 2em;
//  }
//}
//
///* About */
//.home {
//  .about {
//    text-align: center;
//    padding: 4em 0;
//  }
//}
//
//@media screen and (min-width: $medium) {
//  .homeFeaturedLeft, .homeFeaturedRight {
//    height: 70vh;
//    overflow: hidden;
//  }
//  .homeFeaturedLeft {
//    width: 70%;
//    float: left;
//    overflow: hidden;
//  }
//  .homeFeaturedRight {
//    width: 30%;
//    float: right;
//    overflow: initial;
//    > * {
//      margin-top: 0;
//      float: right;
//      height: 50%;
//      width: 90%;
//      &:nth-child(1) {
//        padding: 0 0 .5em;
//      }
//      &:nth-child(2) {
//        padding: .5em 0 0;
//      }
//    }
//  }
//
//}
//
//@media screen and (min-width: $large) {
//
//}