.ctaContent {
  text-align: center;
}

section#ctaSection {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  background: rgba(1, 1, 1, .75);
  @include flexbox(center);
  align-items: flex-start;
  #ctaBackground {
    position: fixed;
    width: inherit;
    height: inherit;
    top: inherit;
    left: inherit;
  }
  #ctaWrapper {
    padding-top: 2em;
    max-width: 550px;
    position: relative;
  }
  i {
    cursor: pointer;
    position: absolute;
    top: 1em;
    right: 0px;
    z-index: 1;
  }
  .gform_wrapper {
    position: relative;
    .gfield {
      padding: 0 !important;
    }
    label {
      display: none !important;
    }
    .gform_footer {
      text-align: right;
    }
    display: none;
    &.active {
      display: block;
    }
  }
  display: none;
  &.active {
    display: flex;
  }
}

ul.ctaFormTabs {
  margin-top: 3em;
  width: 100%;
  @include flexbox(center);
  li {
    cursor: pointer;
    line-height: 3em;
    padding: 0;
    width: 50%;
    text-align: center;
    border: 1px dotted;
  }
  li.active {
    background: $light;
    color: $dark;
  }
}