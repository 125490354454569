.hero-section {
  padding: 0;
}
.hero.jumbotron {
  border-radius: 0;
  margin: 0;
  /* flexbox to improve centering */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}
/* Text inside of Hero */
.hero_unit {
  animation: fadeInRight 2s;
  color: $light;
  text-align: center;
}

/* Btn testing animation on hover */
.hero .btn:hover {
  animation: tada 1s;
}



@media screen and (min-width: $medium) {
  .hero.jumbotron {
    height: 70vh;
    min-height: 550px;
  }
}





// Overlay
.hero-section {
  position: relative;
  overflow: hidden;
}
.overlay:after {
  display: block;
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.26);
}



/*
inspired from http://codepen.io/Rowno/pen/Afykb
*/
//.carousel-fade .carousel-inner .item img{
//  opacity: 0;
//  transition: 400ms all ease;
//}
//
//.carousel-fade .carousel-inner .active img {
//  opacity: 1;
//}
//
//.carousel-fade .carousel-inner .active.left img,
//.carousel-fade .carousel-inner .active.right img {
//  left: 0;
//  opacity: 0;
//  z-index: 1;
//}
//
//.carousel-fade .carousel-inner .next.left img,
//.carousel-fade .carousel-inner .prev.right img {
//  opacity: 1;
//}
//
//.carousel-fade .carousel-control img {
//  z-index: 2;
//}

.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition: 1s opacity ease;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

/*
WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
now override the 3.3 new styles for modern browsers & apply opacity
*/
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}