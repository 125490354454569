// Glyphicons font path
$icon-font-path: "../fonts/";

// Fonts
$font-primary: 'Crimson Text', serif;
$font-secondary: 'Imprint MT W01 Shadow';

// !important - Responsible for cohesive input height & button height
$fields-height: 2.5em;

// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;

/* !important: content should never be more than 55-ish characters per line, it just becomes unreadable */
$content-width: 30.55555em;

// Media Query Sizes
$small: 600px;
$medium: 900px;
$large: 1200px;

// Menu & Header helpers
$header-nav-zindex: 999;

/*
 * Colors
 */
$dark: #333;
$light: white;
$gray: #777;
$lightgray: #999999;

// Brand Colors Specifics - Delete these value and update the rest of the sheet to whatever you want!
$emerald: #2ecc71;
$belizehold: #2980b9;
$carrot: #e67e22;
$pink: #eaaae4;
$darktransparent: rgba(0, 0, 0, 0.9);

// General
$sage-body-background: $dark;
$text-color: $light;
$header-color: $light;
$body-links: $carrot;
$body-links-hover: $belizehold;

// Header
$header-background-color: transparent;

// Non-Mobile Nav
$nav-text-color: $light; /* probably not needed, but just in case */
$nav-link-color: $light;
$nav-link-color-hover: $light;
$nav-dropdown-background: $darktransparent;

// Mobile Nav Button
$mobile-nav-button-text: $light;
$mobile-nav-border-color: $light;
$mobile-nav-button-background: $darktransparent;
$mobile-nav-popup-border: 1px solid;


// Mobile Nav Popup
$mobile-nav-popup-background: $darktransparent;
$mobile-nav-text-color: $light;
$mobile-nav-link-color: $light;

// Buttons
$button-background: $belizehold;
$button-border: $belizehold;
$button-text: $light;
// Buttons:hover
$button-background-hover: $carrot;
$button-border-hover: $carrot;
$button-text-hover: $light;

// Blurbs
$blurb-heading-color: $light;
$blurb-text-color: $light;
// Blurb Icons
$blurb-icon: $light;
$blurb-icon-border: transparent;
$blurb-icon-background: transparent;
// Blurb Icon Hover
$blurb-icon-hover: $light;
$blurb-icon-border-hover: $light;
$blurb-icon-background-hover: transparent;

